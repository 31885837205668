addEventListener("scroll", (e) => {
  if (e.target.scrollingElement.scrollTop > 80) {
    document.querySelector("#navbar").classList.remove("d-none");
    document.querySelector(".new-story-promo").classList.add("promo-hide");
    return;
  }
  document.querySelector(".new-story-promo").classList.remove("promo-hide");
  document.querySelector("#navbar").classList.add("d-none");
});

// NAVIGATION
let oldScrollPos = [];

const closeNavMenu = () => {
  document.querySelector("div.menu-overlay").classList.remove("show");
  document.querySelector("body").classList.remove("o-hidden");
  document.querySelector("div.menu-overlay").classList.add("hide");
  window.scrollTo(oldScrollPos[0], oldScrollPos[1]);
};

const showNavMenu = () => {
  document.querySelector("div.menu-overlay").classList.add("show");
  document.querySelector("body").classList.add("o-hidden");
  document.querySelector("div.menu-overlay").classList.remove("hide");
  oldScrollPos = [window.scrollX, window.scrollY];
  window.scrollTo(0, 0);
};

document
  .querySelector(".hamburger-trigger")
  .addEventListener("click", showNavMenu);

document
  .querySelector("div.overlay-close-trigger")
  .addEventListener("click", closeNavMenu);

document.querySelector("li.menu-entry.red").addEventListener("click", () => {
  closeNavMenu();
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
});

document.querySelector("li.menu-entry.orange").addEventListener("click", () => {
  closeNavMenu();
  document
    .querySelector("h1#stories")
    .scrollIntoView({ behavior: "smooth", block: "center" });
});

document.querySelector("li.menu-entry.green").addEventListener("click", () => {
  closeNavMenu();
  document
    .querySelector("h1#podcast")
    .scrollIntoView({ behavior: "smooth", block: "center" });
});

document.querySelector("li.menu-entry.blue").addEventListener("click", () => {
  closeNavMenu();
  document
    .querySelector("h1#about")
    .scrollIntoView({ behavior: "smooth", block: "center" });
});

document
  .querySelector("#read-paib")
  .addEventListener("click", () =>
    window.open("https://reader.supa.lol/?story=paib", "_blank").focus(),
  );

document
  .querySelector("#paib-cover")
  .addEventListener("click", () =>
    window.open("https://reader.supa.lol/?story=paib", "_blank").focus(),
  );

document
  .querySelector("#mhc-cover")
  .addEventListener("click", () =>
    window.open("https://reader.supa.lol/?story=mhc", "_blank").focus(),
  );

document
  .querySelector("#wszs-cover")
  .addEventListener("click", () =>
    window.open("https://reader.supa.lol/?story=wszs", "_blank").focus(),
  );

document
  .querySelector("#rawa-cover")
  .addEventListener("click", () =>
    window.open("https://reader.supa.lol/?story=rawa", "_blank").focus(),
  );

document
  .querySelector("#emksa-cover")
  .addEventListener("click", () =>
    window.open("https://reader.supa.lol/?story=emksa", "_blank").focus(),
  );

document
  .querySelector("#cola-cover")
  .addEventListener("click", () =>
    window.open("https://reader.supa.lol/?story=cola", "_blank").focus(),
  );

document
  .querySelector("#bb4h-cover")
  .addEventListener("click", () =>
    window.open("https://reader.supa.lol/?story=bb4h", "_blank").focus(),
  );
